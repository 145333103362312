@import "../../assets/scss/variables.scss";

.auth-wrap {
  width: 100%;
  .col-wrap {
    display: flex;
    height: 100%;
    min-height: 100vh;

    @media (max-width: 1279px) {
      display: block;
    }
  }
  .col-right {
    width: 49%;
    background: url("../../assets/images/auth-bg.png"), #101c3d;
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    .content {
      padding: 200px 82px;
      @media (max-width: 1279px) {
        display: none;
      }
    }

    h2 {
      font-family: $font-primary;
      color: #fff;
      font-weight: bold;
      line-height: 60px;
      font-size: 48px;
      margin: 0px;
      padding-top: 156px;
    }
  }
  .col-left {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1279px) {
      width: 100%;
      justify-content: center;
    }
    .form-wrap {
      max-width: 465px;
      width: 100%;
      padding-right: 105px;
      @media (max-width: 599px) {
        padding-right: 0px;
      }
    }
    .primary {
      padding-top: px;
      @media (max-width: 1279px) {
        padding-top: 100px;
      }
      @media (max-width: 599px) {
        padding: 50px 15px;
      }
    }
    h1 {
      font-family: $font-primary;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -1.584px;
      color: #0d152e;
      margin: 200px 0px 60px 0px;
      @media (max-width: 1279px) {
        margin: 0px 0px 30px 0px;
        font-size: 40px;
        line-height: 50px;
      }
      @media (max-width: 599px) {
        margin: 0px 0px 20px 0px;
      }
      @media (max-width: 599px) {
        font-size: 35px;
        line-height: 45px;
      }
    }
    .verify-email {
      margin-top: 200px;
      @media (max-width: 599px) {
        margin-top: 100px;
      }
    }

    .form-inner {
      .radio-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        @media (max-width: 599px) {
          display: block;
        }
      }
      .verify-info {
        .verify-describe {
          font-family: $font-primary;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.6px;
          color: #0d152e;
          margin-right: 25px;
          max-width: 462px;
          margin-bottom: 40px;
        }
        @media (max-width: 599px) {
          font-size: 15px;
          line-height: 19px;
        }

        // .verify-describe {
        // }
      }

      lable,
      .MuiFormControlLabel-label {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.6px;
        color: #0d152e;
        margin-right: 25px;
        display: block;
        @media (max-width: 599px) {
          margin-bottom: 5px;
        }
      }
      .MuiFormControlLabel-root {
        margin: 0px;
      }
      .MuiRadio-root {
        padding: 0px 8px 0px 0px;
      }
      .Mui-checked {
        color: #416ff4;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      .input-wrap {
        margin-bottom: 30px;
        lable {
          margin-bottom: 15.5px;
          @media (max-width: 1279px) {
            margin-bottom: 10.5px;
          }
        }
        .MuiTextField-root {
          width: 100%;
        }
        .MuiInputBase-root:hover {
          border-color: #eaeced;
        }
        input {
          font-family: $font-primary;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: -0.528px;
          color: #81838c;
          padding: 12.5px;
          width: 100%;
          border: 1px solid #eaeced;
          border-radius: 5px;
          outline: none;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
      .remember-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35.5px;

        .PrivateSwitchBase-input {
          width: 20px;
          height: 20px;
        }

        @media (max-width: 1279px) {
          margin-bottom: 25.5px;
        }
        .MuiCheckbox-root {
          padding: 0px 10px 0px 0px;
        }
        .MuiLink-root {
          font-family: $font-primary;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          text-align: right;
          letter-spacing: -0.528px;
          color: #0052fe;
          text-decoration: none;
        }
        .MuiFormControlLabel-label {
          font-weight: 400 !important;
        }
        .forgotpwd {
          color: #0052fe;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          /* identical to box height */
          text-decoration: none;
          text-align: right;
          letter-spacing: -0.528px;
        }
      }
      .submit-btn {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.528px;
        color: #ffffff;
        background: #0052fe;
        border-radius: 6px;
        text-transform: capitalize;
        padding: 19.5px 10px;
        margin-bottom: 25.5px;
        text-transform: capitalize;
        @media (max-width: 1279px) {
          margin-bottom: 22.5px;
        }
      }
      .submit-btn:hover {
        background-color: #0b4bd4;
      }
      .verify-btn {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.528px;
        color: #ffffff;
        background: #0052fe;
        border-radius: 6px;
        text-transform: capitalize;
        padding: 19.5px 10px;
        margin: 40px 0px 40px 0px;
        @media (max-width: 599px) {
          padding: 12.5px 10px;
        }
      }
      .account-text {
        font-family: $font-primary;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.528px;
        color: #81838c;
        margin: 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 304px;

        .createaccount {
          color: #0052fe;
          text-decoration: none;
          margin-left: 5px;
        }

        .sign-in {
          color: #0052fe;
          text-decoration: none;
          margin-left: 5px;
        }
      }
      @media (max-width: 599px) {
        font-size: 15px;
        line-height: 19px;
      }
      .resend-text,
      .verify-describe {
        color: #0052fe;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.6px;
        cursor: pointer;
        margin-left: 5px;
        display: contents;
      }
      .create-ac {
        color: #0052fe;
        text-decoration: none;
      }
    }
  }
  .enter-otp {
    input {
      width: 44px !important;
      height: 46px !important;
      display: flex;
      justify-content: space-around;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height */

      letter-spacing: -0.528px;
      font-family: $font-primary;
      border-radius: 5px;
      border: 1px solid #eaeced;
      color: #81838c;
      &:focus-visible {
        border: 1px solid #eaeced !important;
      }
    }
  }
}
