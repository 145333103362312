.faq-wrap {
  .MuiAccordion-root::before {
    display: none;
  }
  .MuiAccordion-root {
    box-shadow: none;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 9px #00000017;
    cursor: pointer;
    background: #fff;
    margin-bottom: 0.5em;
    border-radius: 5px;
    overflow: hidden;
    svg {
      fill: #0d152e;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0px;
    }
    .MuiAccordionSummary-root {
      min-height: auto;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      background: #0052fe;
    }
    .MuiAccordionSummary-root.Mui-expanded p {
      color: #fff;
    }
    .MuiAccordionSummary-root.Mui-expanded svg {
      fill: #fff;
    }
    .MuiAccordionSummary-root p {
      font-size: 18px;
      line-height: 28.25px;
      font-weight: 600;
      font-family: DM Sans;
      color: #0d152e;
      letter-spacing: 0;
    }
  }
  .MuiAccordionDetails-root p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: DM Sans;
    color: #0d152e;
    letter-spacing: -0.528px;
    li {
      margin-bottom: 6px;
    }
  }
}
