@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.MuiDrawer-paper {
  width: 100% !important;

  .header-btn {
    font-size: 16px;
    font-weight: 700;
    font-family: $font-primary;
    text-transform: capitalize;
    padding: 0px;
    margin: 25px 0px;
    line-height: 21px;
    letter-spacing: -0.6px;
    color: $color-black;
    &:hover {
      background-color: transparent;
    }
  }
  .active {
    color: $color-blue;
  }

  .signin-btn {
    width: 110px !important;
    height: 45px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: $font-primary !important;
    color: $color-white !important;
    background-color: $color-blue !important;
    border-radius: $bRadius !important;
    text-transform: capitalize !important;
  }
  // .signin-btn:hover {
  //   background-color: #0b4bd4;
  // }
}
.Toastify__toast-body {
  .Toastify__toast-icon {
    svg {
      fill: aliceblue;
    }
  }
}
