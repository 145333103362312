@import "../../assets/scss/variables.scss";
.customer-container {
  .Myprofile-wrap {
    margin-bottom: 70px;
    h1 {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -1.584px;
      color: #0d152e;
      margin-bottom: 60px;
      @media (max-width: 599px) {
        font-size: 28px;
        line-height: 48px;
        margin-bottom: 25px;
      }
    }

    .customer-detail-container {
      max-width: 1138px;

      .customer-detail {
        .display-flex {
          display: flex;
          border: 1px solid #d2d3d5;
          padding: 25px 30px 30px;
          word-break: break-all;
          @media (max-width: 599px) {
            padding: 23px 23px 23px;
          }
          .MuiAvatar-circular {
            width: 100px;
            height: 100px;
          }
          .customer-pic {
            width: 76px;
            height: 76px;
            margin-right: 30px;
            svg {
              display: inline-block;
              width: 22px;
              height: 22px;
              margin-bottom: 0;
              border-radius: 100%;
              background: #ffffff;
              border: 1px solid transparent;
              box-shadow: 0px 2px 4px 0px #0000001f;
              cursor: pointer;
              font-weight: normal;
              transition: all 0.2s ease-in-out;
              position: absolute;
              color: #0052fe;
              padding: 5px;
              top: 0px;
              right: -1px;
            }
            @media (max-width: 599px) {
              margin-right: 18px;
            }
          }
          .customer-name {
            font-size: 24px;
            line-height: 31px;
            font-weight: 700;
            font-style: normal;
            font-family: $font-primary;
            color: #0d152e;
            margin-bottom: 8px;
            @media (max-width: 599px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
          .customer-mail,
          .customer-number {
            font-size: 20px;
            line-height: 26px;
            font-weight: 400;
            font-style: normal;
            font-family: $font-primary;
            color: #0d152e;
            @media (max-width: 599px) {
              font-size: 17px;
              line-height: 23px;
            }
          }
        }
      }

      .customer-address {
        .box-border {
          border: 1px solid #d2d3d5;
          padding: 33px 30px 29px;
          min-height: 93px;

          @media (max-width: 1024px) {
            padding: 33px 15px 29px;
          }

          @media (max-width: 599px) {
            padding: 25px 25px 25px;
          }
          .Add-title {
            font-size: 24px;
            line-height: 31.25px;
            font-weight: 700;
            font-style: normal;
            font-family: $font-primary;
            color: #0d152e;
            @media (max-width: 599px) {
              font-size: 20px;
              line-height: 23.25px;
            }
          }

          .address {
            font-size: 20px;
            line-height: 26px;
            font-style: normal;
            font-family: $font-primary;
            color: #0d152e;
            margin-top: 9px;
            @media (max-width: 599px) {
              font-size: 17px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
  .company-contact.service-box {
    display: block !important;
    padding: 30px;
    .contact {
      margin: 0px 0px 8px 0px;
    }
  }
  .company-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // align-items: center;
    border: 1px solid #d2d3d5;

    .contact {
      font-size: 24px;
      line-height: 31px;
      font-weight: 700;
      font-style: normal;
      font-family: $font-primary;
      color: #0d152e;
      margin-left: 30px;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 25px;
        margin-left: 23px;
      }
    }
    .number {
      font-size: 48px;
      line-height: 60px;
      font-weight: 700;
      font-style: normal;
      font-family: $font-primary;
      color: #0d152e;
      margin: 30px 30px 30px 0px;

      @media (max-width: 599px) {
        font-size: 42px;
        line-height: 52px;
        margin: 23px 23px 23px 0px;
      }
    }
    .service_name {
      color: #0d152e;
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
  }
  .service-grid {
    border: 1px solid #d2d3d5;
    padding: 30px 0px 30px 30px;
    @media (max-width: 599px) {
      padding: 23px 0px 23px 23px;
    }
    .customer-service {
      font-size: 24px;
      line-height: 31px;
      font-weight: 700;
      font-style: normal;
      font-family: $font-primary;
      color: #0d152e;
      margin-bottom: 9px;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .service-describe {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      font-style: normal;
      font-family: $font-primary;
      color: #0d152e;

      @media (max-width: 599px) {
        font-size: 17px;
        line-height: 23px;
      }
    }
  }
}
