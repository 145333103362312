@import "../../assets/scss/variables.scss";

.privacy-policy-container {
  padding-top: 27px;
  padding-bottom: 86px;
  display: flex;
  justify-content: center;

  .terms-conditions-container {
    max-width: 762px;

    h1 {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      color: $color-black;
      text-align: center;
      margin-bottom: 71px;
      letter-spacing: -1.98px;
      @media (max-width: 599px) {
        font-size: 32px;
        line-height: 46px;
        margin-bottom: 25px;
      }
    }
    .f16-28 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      font-family: $font-primary;
      color: #0d152e;
      letter-spacing: -0.528px;
      @media (max-width: 1024px) {
        text-align: justify;
      }
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
      }
      .bold-text {
        font-weight: 700;
      }
    }
    // .t-heading {
    //   font-size: 24px;
    //   line-height: 31.25px;
    //   font-weight: 700;
    //   font-family: $font-primary;
    //   color: #0d152e;
    //   margin-bottom: 30px;
    //   @media (max-width: 599px) {
    //     font-size: 17px;
    //     margin-bottom: 15px;
    //   }
    // }
    .note {
      font-size: 20px;
      line-height: 27.25px;
      font-weight: 600;
      font-family: DM Sans;
      color: #0d152e;
      margin-bottom: 10px;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 24.25px;
        text-align: justify;
      }
    }
    .contents-head {
      font-size: 24px;
      line-height: 31.25px;
      font-weight: 700;
      font-family: $font-primary;
      color: #0d152e;
      margin-bottom: 33px;
      @media (max-width: 599px) {
        font-size: 20px;
        line-height: 22.25px;
        text-align: justify;
      }
    }
    .questions {
      font-size: 18px;
      line-height: 27.25px;
      font-weight: 500;
      font-family: DM Sans;
      color: #0d152e;
      margin-bottom: 7px;
      text-decoration: none;
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 23.25px;
        // text-align: justify;
      }
    }

    .que-info {
      .que-head {
        font-size: 24px;
        line-height: 31.25px;
        font-weight: 700;
        font-family: DM Sans;
        color: #0d152e;
        margin-bottom: 24px;
        @media (max-width: 1024) {
          text-align: justify;
        }
        @media (max-width: 599px) {
          font-size: 17px;
          line-height: 25.25px;
          text-align: justify;
        }
      }
      .sub-head {
        font-size: 18px;
        line-height: 28.25px;
        font-weight: 600;
        font-family: DM Sans;
        color: #0d152e;
        margin-bottom: 20px;
        @media (max-width: 599px) {
          font-size: 18px;
          line-height: 26.25px;
          text-align: justify;
        }
      }
      .in-short-head {
        font-size: 16px;
        line-height: 28.25px;
        font-weight: 600;
        font-family: DM Sans;
        font-style: italic;
        color: #0d152e;
        margin-bottom: 20px;
        @media (max-width: 599px) {
          text-align: justify;
        }
      }
      .in-short-italic {
        margin-left: 10px;
        font-size: 16px;
        line-height: 28.25px;
        font-weight: 500;
        font-family: DM Sans;
        font-style: italic;
        color: #0d152e;
        margin-bottom: 20px;
      }

      .light-italic {
        font-size: 16px;
        line-height: 28.25px;
        font-weight: 400;
        font-family: DM Sans;
        font-style: italic;
        color: #0d152e;
      }

      .in-short {
        margin-left: 10px;
        font-size: 16px;
        line-height: 28.25px;
        font-weight: 400;
        font-family: DM Sans;
        color: #0d152e;
        margin-bottom: 20px;
      }
      .que-describe {
        font-size: 16px;
        line-height: 28.25px;
        font-weight: 400;
        font-family: DM Sans;
        color: #0d152e;
        margin-bottom: 20px;
        @media (max-width: 599px) {
          text-align: justify;
        }
        @media (max-width: 320px) {
          font-size: 14px;
          line-height: 26.25px;
          text-align: justify;
        }
      }
    }

    .t-mb-41 {
      margin-bottom: 41px;
      @media (max-width: 599px) {
        margin-bottom: 10px;
      }
    }

    .br {
      @media (max-width: 599px) {
        display: contents;
      }
    }
  }
}
