@import "../../assets/scss/variables.scss";
.verification-container {
  .verified-detail {
    .verified-title {
      margin-top: 96px;
      font-size: 48px;
      line-height: 60px;
      font-weight: 700;
      text-align: center;
      font-style: normal;
      font-family: $font-primary;
      color: #0d152e;
      max-width: 764px;
      letter-spacing: -1.584px;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        margin-top: 46px;
      }
      @media (max-width: 599px) {
        font-size: 27px;
        line-height: 36px;
        margin-top: 36px;
      }
    }
    .page-describe {
      font-size: 16px;
      line-height: 21px;
      font-weight: 500;
      font-style: normal;
      font-family: $font-primary;
      letter-spacing: -0.6px;
      color: #0d152e;
      text-align: center;
      margin-bottom: 7px;
    }
  }
  .MuiGrid-container {
    @media (min-width: 834px) {
      display: contents;
    }
  }

  .upload-wrap {
    @media (max-width: 834px) {
      display: grid;
    }
  }
  .upload-detail {
    @media (max-width: 810px) {
      display: contents;
    }
    @media (max-width: 599px) {
      display: block;
    }
  }
  .uploadfile-detail {
    &:last-child {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
      }
    }
  }
  .uploadfile-detail {
    &:first-child {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) {
        display: flex;
        justify-content: center;
      }
    }
    // max-width: 380px;

    .border-box {
      // height: 183px;
      // width: 380px;
      width: 100%;
      max-width: 380px;
      border: 1px solid #d2d3d5;
      padding: 33px 3px 14px 15px;
      .proof-title {
        font-size: 16px;
        line-height: 20.83px;
        letter-spacing: -0.6 px;
        font-weight: 500;
        font-style: normal;
        font-family: $font-primary;
        color: #0d152e;
        margin-bottom: 9px;
        text-align: center;
      }
      .notify {
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        letter-spacing: -0.528px;
        font-style: normal;
        font-family: $font-primary;
        color: #81838c;
        margin-bottom: 14px;
        text-align: center;
        max-width: 334px;
        margin-left: auto;
        margin-right: auto;
      }
      .license-image {
        width:200px;
        height:200px;
        object-fit: contain;
      }
      .MuiGrid-container {
        @media (max-width: 810px) {
          display: grid;
        }
      }
      .uploadRegistration,
      .upload-license {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height */
        text-align: center;
        letter-spacing: -0.6px;
        text-transform: capitalize;
        color: #0052fe;
        background-color: #ffffff;
        border: 1px dotted;
        border: 1px dashed #d2d3d5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10.6px 15px 11.6px 16.3px;

        .uploadRegistration:hover {
          background-color: #0b4bd4;
        }
        .uploadImg {
          // margin-right: 16.5px;
        }
      }
    }
  }
  .submit-btn {
    background: #0052fe;
    border-radius: 6px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.528px;
    color: #ffffff;
    padding: 20px 204.5px 19px 206.5px;
    margin-top: 40px;
    text-transform: capitalize;
    @media (max-width: 599px) {
      padding: 20px 143.5px 19px 143.5px;
    }
  }
  .submit-btn:hover {
    background-color: #0b4bd4;
  }
}
