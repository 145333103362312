@import "../../../assets/scss/variables.scss";

.client-topbar {
  .MuiToolbar-root {
    padding: 24px 40px;
    @media (max-width: 599px) {
      padding: 24px 20px;
    }
  }
  .img-logo {
    padding: 0px;
    margin: 0px;
  }
  .MuiPaper-root {
    background-color: $color-white;

    .header-btn {
      font-size: 16px;
      font-weight: 700;
      font-family: $font-primary;
      text-transform: capitalize;
      padding: 0px;
      margin-right: 50px;
      line-height: 21px;
      letter-spacing: -0.6px;
      color: $color-black;

      &:hover {
        background-color: transparent;
      }
    }
    .header-btn:hover {
      color: $color-blue;
    }
    .active {
      color: $color-blue;
    }

    .signin-btn {
      // width: 110px;
      height: 45px;
      font-size: 16px;
      font-weight: 700;
      font-family: $font-primary;
      color: $color-white;
      background-color: $color-blue;
      border-radius: $bRadius;
      text-transform: capitalize;
      padding: 12px 28px 12px 29px;
    }
    .signin-btn:hover {
      background-color: #0b4bd4;
    }
  }

  // RESPONSIVE TOPBAR
  .responsive-drawer {
    width: 100%;
  }
}
