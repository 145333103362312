@import "../../assets/scss/variables.scss";
.TermandCondition {
  padding-top: 27px;
  padding-bottom: 86px;
  display: flex;
  justify-content: center;
  .terms-container {
    max-width: 762px;
    h1 {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      color: $color-black;
      text-align: center;
      margin-bottom: 71px;
      letter-spacing: -1.98px;
      @media (max-width: 599px) {
        font-size: 32px;
        line-height: 46px;
        margin-bottom: 25px;
      }
    }
    .detail {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      font-family: $font-primary;
      color: #0d152e;
      letter-spacing: -0.528px;
      @media (max-width: 1024px) {
        text-align: justify;
      }
      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 24px;
        text-align: justify;
      }
    }
    .dark-font {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      font-family: $font-primary;
      color: #0d152e;
      letter-spacing: -0.528px;
      @media (max-width: 599px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
