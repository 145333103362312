@import "../../assets/scss/variables.scss";

.home-page-container {
  padding-top: 99.58px;

  @media (max-width: 599px) {
    padding-top: 20px;
  }
  @media (max-width: 1024px) {
    padding-top: 80px;
  }
  .MuiContainer-root {
    padding-right: auto;
  }
  .MuiContainer-root {
    @media (max-width: 2560px) {
      max-width: 1252px;
    }
    @media (max-width: 1280px) {
      max-width: 1216px;
    }
  }

  .home-top-sec-1 {
    background: url("../../assets/images/h-vec-1.png");
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 899px) {
      background: none;
    }
    @media (max-width: 1024px) {
      .MuiGrid-container {
        .MuiGrid-item {
          padding-top: 53px;
        }
      }
    }

    .service-heading {
      max-width: 527px;
      @media (max-width: 599px) {
        font-size: 27px;
        line-height: 33px;
      }
      @media (max-width: 320px) {
        font-size: 24px;
        line-height: 29px;
      }

      @media (max-width: 1024px) {
        max-width: none;
      }
    }
    .service-info {
      max-width: 477px;
      @media (max-width: 1024px) {
        max-width: none;
      }
    }
    h2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 70px;
      letter-spacing: -2.112px;
      color: #0d152e;
      margin-bottom: 11px;
      margin-top: 25.71px;

      @media (max-width: 1024px) {
        font-size: 57px;
        line-height: 62px;
        margin-bottom: 8px;
        margin-top: 10.71px;
      }
      @media (max-width: 899px) {
        text-align: center;
      }
    }

    p {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.66px;
      color: #81838c;
      margin-bottom: 51px;
      max-width: 477px;
      @media (max-width: 1024px) {
        margin-bottom: 31px;
      }
      @media (max-width: 899px) {
        text-align: center;
      }
      @media (max-width: 599px) {
        font-size: 17px;
        line-height: 29px;
        text-align: justify;
      }
    }

    .store-links {
      display: flex;
      margin-bottom: 232.29px;

      @media (max-width: 1920px) {
        margin-left: 0px;
      }

      // @media (max-width: 1280px) {
      //   margin-left: 76px;
      // }

      @media (max-width: 899px) {
        justify-content: center;
        margin-bottom: 57.29px;
      }
      @media (max-width: 360px) {
        display: grid;
      }
      @media (max-width: 390px) {
        display: grid;
      }

      .app-store {
        margin-right: 19.38px;
        @media (max-width: 1024px) {
          margin-bottom: 5px;
        }

        @media (max-width: 393px) {
          margin-right: 3.38px;
        }
        @media (max-width: 390px) {
          margin-right: 0px;
        }
      }
    }

    .videoplayer {
      position: relative;
      .video-info {
        margin-top: 60px;
        border-radius: 45px;
        // padding-left: 61.85px;
        box-shadow: 31px 40px 78px rgba(111, 118, 138, 0.156863);
        @media (max-width: 768px) {
          margin-top: 0px;
        }
        @media (max-width: 599px) {
          margin-top: 0px;
        }
      }

      .play-btn {
        width: 50%;
        position: absolute;
        left: 55%;
        top: 66%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        @media (max-width: 599px) {
          left: 61%;
          top: 66%;
        }
      }
      // .pause-icon {
      //   width: 30%;
      //   position: absolute;
      //   left: 55%;
      //   top: 60%;
      //   transform: translate(-50%, -50%);
      // }
    }

    .home-sec1 {
      width: auto;
      height: auto;
    }
  }

  .helping-section {
    padding-top: 57.71px;
    margin-bottom: 100px;
    @media (max-width: 1024px) {
      padding-top: 28.71px;
      margin-bottom: 50px;
    }
    @media (max-width: 899px) {
      padding-top: 0.71px;
      margin-bottom: 45px;
    }

    .MuiAvatar-root {
      width: auto;
      height: auto;
    }
    .MuiAvatar-root {
      width: 95%;
      height: 100%;
      @media (max-width: 1024px) {
        width: 100%;
        height: 89%;
      }
      @media (max-width: 428px) {
        width: 100%;
        height: 87%;
      }
    }

    .MuiAvatar-img {
      @media (max-width: 412px) {
        width: 100%;
        height: 89%;
      }
    }
    .MuiGrid-item {
      padding-left: 16px;
      padding-right: 11px;
    }
    .MuiGrid-item {
      padding-top: 15px;
    }
    h2 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -1.584px;
      color: #0d152e;
      margin-bottom: 11px;
      max-width: 492px;
      @media (max-width: 1024px) {
        font-size: 43px;
        line-height: 55px;
        margin-bottom: 9px;
      }
      @media (max-width: 899px) {
        max-width: inherit;
        text-align: center;
        font-size: 25px;
        line-height: 38px;
      }
      @media (max-width: 834px) {
        margin-left: 10px;
        text-align: inherit;
      }
      @media (max-width: 390px) {
        font-size: 25px;
        line-height: 36px;
      }
      @media (max-width: 375px) {
        font-size: 24px;
        line-height: 36px;
      }
      @media (max-width: 360px) {
        font-size: 23px;
        line-height: 33px;
      }
    }

    p {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.66px;
      color: #81838c;
      max-width: 454px;
      // margin-bottom: 27px;
      @media (max-width: 1024px) {
        max-width: inherit;
        /* text-align: center; */
        margin-left: 10px;
        text-align: inherit;
      }
      @media (max-width: 899px) {
        max-width: inherit;
        text-align: inherit;
      }
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .options {
      margin-top: 27px;

      .option {
        display: flex;
        align-items: flex-start;
        margin-bottom: 44px;
        cursor: pointer;
        .quick {
          margin-right: 30px;
        }
        .quick rect {
          fill: #fff !important;
          transition: all 0.5s;
        }
        .quick path {
          fill: #0052fe;
          transition: all 0.5s;
        }
        &:hover .quick path {
          fill: #fff;
          transition: all 0.5s;
        }
        &:hover .quick rect {
          fill: #0052fe !important;
          transition: all 0.5s;
        }

        .opt-txt {
          width: 354px;
          .head {
            font-size: 20px;
            line-height: 26.04px;
            letter-spacing: -0.66px;
            font-weight: 700;
            font-family: $font-primary;
            color: #0d152e;
            margin-bottom: 16px;
            @media (max-width: 1024px) {
              margin-bottom: 5px;
              text-align: left;
              margin-left: 10px;
            }
            @media (max-width: 599px) {
              font-size: 18px;
              line-height: 22.04px;
            }
          }
          .describe {
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.53px;
            font-weight: 400;
            font-family: $font-primary;
            color: #81838c;
            @media (max-width: 599px) {
              font-size: 15px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }

  .home-sec2 {
    @media (max-width: 834px) {
      margin-top: 70px;
    }
  }

  .provider {
    background: #101c3d;
    padding: 113px 0px 106px 0px;
    margin-bottom: 177px;
    @media (max-width: 1024px) {
      margin-bottom: 60px;
    }
    @media (max-width: 599px) {
      margin-bottom: 80px;
      padding: 70px 0px 65px 0px;
    }
    .app-head {
      font-size: 48px;
      line-height: 62.5px;
      font-weight: 700;
      letter-spacing: -1.58px;
      text-align: center;
      font-family: $font-primary;
      color: #ffffff;
      margin-bottom: 14px;
      @media (max-width: 899px) {
        font-size: 43px;
        line-height: 55.5px;
      }
      @media (max-width: 599px) {
        font-size: 33px;
        line-height: 40.5px;
      }
    }
    .app-decribe {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      letter-spacing: -0.66px;
      color: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.6;
      margin-bottom: 100px;
    }

    .grid-provider {
      .grid-item {
        align-items: center;
      }
      .services {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        /* or 158% */
        text-align: center;
        letter-spacing: -0.5px;
        color: #f5f5f5;
        mix-blend-mode: normal;
        opacity: 0.6;
        max-width: 318px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 899px) {
          margin-bottom: 12px;
        }
      }
      .last-ser {
        max-width: 358px;
      }
      .ser-name {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -1.122px;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .ser-icon {
        margin-bottom: 50px;
        width: 90.75px;
      }
      .numbers {
        margin-bottom: 25px;
      }
    }
  }

  .provide-wrap {
    // display: flex;
    .sec-3grid {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .help-head {
      max-width: 488px;

      .help-head {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        /* or 125% */
        letter-spacing: -1.584px;
        color: #0d152e;
        margin-bottom: 16px;

        @media (max-width: 1024px) {
          font-size: 43px;
          line-height: 50px;
          margin-bottom: 12px;
        }
        @media (max-width: 599px) {
          font-size: 35px;
          line-height: 45px;
        }
        @media (max-width: 360px) {
          font-size: 31px;
          line-height: 43px;
        }
      }
      .help-describe {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        /* or 170% */
        letter-spacing: -0.66px;
        color: #81838c;
        @media (max-width: 899px) {
          font-size: 18px;
          line-height: 30px;
        }
        @media (max-width: 360px) {
          font-size: 15px;
          line-height: 26px;
        }
      }
    }
    .home-sec3 {
      width: auto;
      height: auto;
      .MuiAvatar-img {
        width: 85%;
        height: 100%;
      }
    }
  }

  .siri {
    @media (max-width: 899px) {
      margin-bottom: 60px;
    }
    .sec-3grid {
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1024px) {
        margin-bottom: 50px;
      }
    }
    .home-sec3 {
      width: auto;
      height: auto;
      .MuiAvatar-img {
        width: 95%;
        height: 100%;
      }
    }

    .siri-describe {
      .siri-head {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        /* or 125% */
        letter-spacing: -1.584px;
        color: #0d152e;
        max-width: 571px;
        @media (max-width: 1024px) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: 899px) {
          max-width: inherit;
          text-align: center;
        }
        @media (max-width: 834px) {
          font-size: 36px;
          line-height: 46px;
        }
        @media (max-width: 599px) {
          font-size: 26px;
          line-height: 37px;
        }
        @media (max-width: 412px) {
          font-size: 25px;
          line-height: 35px;
        }
        @media (max-width: 360px) {
          font-size: 22px;
          line-height: 30px;
        }
        @media (max-width: 375px) {
          font-size: 24px;
          line-height: 30px;
        }
        @media (max-width: 390px) {
          font-size: 23px;
          line-height: 33px;
        }
        // @media (max-width: 599px) {
        //   font-size: 24px;
        //   line-height: 34px;
        // }
      }
      .siri-provider {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        /* or 170% */
        letter-spacing: -0.66px;
        color: #81838c;
        max-width: 505px;
        margin-top: 15px;
        @media (max-width: 899px) {
          max-width: inherit;
          align-items: center;
        }
        @media (max-width: 899px) {
          max-width: inherit;
          margin-top: 25px;
        }
        @media (max-width: 599px) {
          font-size: 18px;
          line-height: 31px;
          margin-bottom: 20px;
        }
      }

      .display-flex {
        display: flex;
        margin-right: 45px;
        margin-bottom: 25px;
        margin-top: 25px;
        @media (max-width: 599px) {
          display: block;
          margin: 0px;
        }
        .vcss {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 25px;
          /* or 139% */
          letter-spacing: -0.594px;
          color: #182748;
          display: flex;
          align-items: center;
          width: 50%;
          @media (max-width: 1024px) {
            font-size: 17px;
          }
          @media (max-width: 599px) {
            width: 100%;
          }
        }

        .icon {
          margin-right: 10px;
        }
      }
      .get-btn {
        @media (max-width: 834px) {
          display: block;
          // justify-content: center;
        }

        .started-btn {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
          letter-spacing: -0.528px;
          color: #ffffff;
          background: #0052fe;
          padding: 22px 37.5px 22px 37.5px;
          margin-top: 37px;
          text-transform: capitalize;
          text-decoration: none;
          display: inline-block;
        }
        .started-btn:hover {
          background-color: #0b4bd4;
          text-decoration: none;
        }
      }
    }
  }
  .bluebg {
    background-image: url(../../assets/images/bg.png);

    .MuiGrid-item {
      // margin-top: 141px;
    }
    // margin-bottom: 148px;
    .download-info {
      .download-app {
        // margin: 141px 0px 135px 0px;
        .download-head {
          font-family: $font-primary;
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 60px;
          /* or 125% */
          letter-spacing: -1.584px;
          color: #ffffff;
          max-width: 399px;
          margin: 141px auto 38.5px 80px;
          // @media (min-width: 1280px) {
          //   margin: 141px auto 38.5px 71px;
          // }
          // @media (max-width: 1920px) {
          //   margin: 141px auto 38.5px 80px;
          // }
          @media (max-width: 1024px) {
            margin: 141px auto 38.5px 0px;
          }

          @media (max-width: 599px) {
            margin: 84px 0px 34.5px 0px;
          }
          @media (max-width: 834px) {
            margin: 141px auto 34.5px 0px;
            text-align: center;
          }
          @media (max-width: 899px) {
            max-width: inherit;
            font-size: 45px;
            line-height: 55px;
          }
          .MuiGrid-item {
            margin-top: 117px;
          }
          @media (max-width: 599px) {
            font-size: 41px;
            line-height: 48px;
            margin-bottom: 25.5px;
          }
          @media (max-width: 360px) {
            font-size: 35px;
            line-height: 44px;
            margin-bottom: 25.5px;
          }
          // margin-bottom: 37.5px;
        }

        .store-links {
          display: flex;
          justify-content: flex-start;
          margin-left: 80px;
          @media (max-width: 1024px) {
            margin-left: 0px;
            display: block;
          }
          @media (max-width: 834px) {
            margin-left: 75px;
          }
          @media (max-width: 810px) {
            margin-left: 67px;
          }

          @media (max-width: 768px) {
            margin-left: 45px;
          }
          @media (max-width: 480px) {
            margin-left: 0px;
          }
          @media (max-width: 360px) {
            margin-left: 0px;
          }
          // @media (max-width: 768px) {
          //   margin-left: 48px;
          // }

          // @media (max-width: 599px) {
          //   margin-left: 0px;
          //   display: grid;
          // }

          @media (max-width: 599px) {
            display: block;
            text-align: center;
          }
          @media (max-width: 360px) {
            display: block;
          }
          .app-store {
            margin-right: 19.38px;
            @media (max-width: 599px) {
              margin-right: 0px;
              // margin-bottom: 10.38px;
            }
            @media (max-width: 480px) {
              margin-right: 8px;
            }
            @media (max-width: 414px) {
              margin-right: 0px;
            }
            @media (max-width: 412px) {
              margin-left: 0px;
            }
            @media (max-width: 390px) {
              margin-left: 0px;
            }
            @media (max-width: 379px) {
              margin-left: 0px;
            }
            @media (max-width: 375px) {
              margin-left: 0px;
            }
            @media (max-width: 360px) {
              margin-right: 0px;
            }
          }
          .play-store {
            @media (max-width: 360px) {
              margin-left: 0px;
            }
          }
        }
      }
    }
    .download-img {
      // margin-right: 222px;
      width: auto;
      height: auto;
      margin-bottom: -2px;
      max-width: 505px;
      max-height: 404px;
      margin-top: 103px;
      // border-bottom: 2px solid #ffffff;
    }
  }

  .MuiContainer-root {
    @media (max-width: 1024px) {
      padding-left: 28px;
      padding-right: 35px !important;
    }
    @media (max-width: 360px) {
      padding-right: 34px !important;
    }
  }
  .slick-slider {
    .slick-next:before {
      font-size: 35px;
      color: #000000;

      @media (max-width: 1024px) {
        font-size: 25px;
      }
      @media (max-width: 599px) {
        font-size: 23px;
      }
    }
    .slick-prev:before {
      font-size: 35px;
      color: #000000;
      margin-left: -16px;

      @media (max-width: 1024px) {
        font-size: 25px;
        margin-left: 0px;
      }
      @media (max-width: 599px) {
        font-size: 23px;
        margin-left: 0px;
      }
    }
  }
  .client-wrap {
    margin-bottom: 80px;
    margin-top: 148px;
    display: flex !important;

    @media (max-width: 599px) {
      margin-top: 30px;
    }

    .MuiGrid-item {
      padding-left: 26px;
      @media (max-width: 599px) {
        padding-left: 60px;
      }
    }

    .client-detail {
      // position: relative;

      .client-pic {
        display: flex;
        justify-content: center;
        position: relative;
        .quote-img {
          position: absolute;
          z-index: 999;
          right: 69px;
          top: -25px;
        }
        .client-photo {
          max-width: 505px;
          width: 100%;
          height: 100%;
          .MuiAvatar-img {
            width: 623.71px;
            border-radius: 27px 27px 94px 27px;
            max-height: 598px;
            @media (max-width: 599px) {
              width: 99%;
              height: 100%;
              border-radius: 27px;
            }
          }
        }
      }
      // .previous {
      //   position: absolute;
      // }
    }
  }
  .client-detail {
    .client-test {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      /* or 125% */
      letter-spacing: -1.584px;
      color: #0d152e;
      max-width: 275px;
      margin-bottom: 44px;
      margin-top: 16px;
      @media (max-width: 899px) {
        max-width: inherit;
        font-size: 43px;
        line-height: 52px;
        text-align: center;
        margin-bottom: 22px;
      }
      @media (max-width: 599px) {
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 16px;
      }
    }
    // .starts {
    //   @media (max-width: 899px) {
    //     text-align: center;
    //   }
    // }
    .MuiBox-root {
      margin-bottom: 25px;
      @media (max-width: 899px) {
        text-align: center;
        justify-content: center;
      }
      @media (max-width: 599px) {
        margin-bottom: 20px;
      }
    }

    .client-describe {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      /* or 170% */
      letter-spacing: -0.66px;
      color: #0d152e;
      max-width: 497px;
      margin-bottom: 52px;
      @media (max-width: 899px) {
        max-width: inherit;
        font-size: 18px;
        line-height: 30px;
      }
      @media (max-width: 899px) {
        max-width: inherit;
        font-size: 18px;
        line-height: 30px;
      }
      @media (max-width: 599px) {
        text-align: center;
        font-size: 17px;
        line-height: 28px;
      }
    }
    .client-detail {
      display: flex;
      margin-bottom: 71px;
      align-items: center;
      @media (max-width: 899px) {
        display: contents;
      }
      .client-name {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        /* identical to box height */
        letter-spacing: -0.66px;
        color: #0d152e;
        margin-bottom: 10px;
      }
      .client-profession {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height */
        letter-spacing: -0.528px;
        color: #9ea1ab;
      }
      .symbol {
        margin-right: 16px;
      }
    }
    .arrow-wrap {
      display: flex;
      justify-content: space-between;
    }
    .previous {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.528px;
      color: #81838c;
      border-bottom: 2px solid #81838c;
      width: fit-content;
      padding-bottom: 10px;
      margin-right: 15px;
      cursor: pointer;
      @media (max-width: 899px) {
        // display: contents;
        // // border-bottom: 2px solid #81838c;
        // // width: fit-content;
        border-bottom: 2px solid #81838c;
        display: initial;
      }
    }
  }

  .get-in-touch {
    display: flex;
    justify-content: center;
    margin-bottom: -318px;

    .help-box {
      background-color: #ffffff;
      padding: 70px 59px 58px 59px;

      border-radius: 22px;
      display: grid;
      box-shadow: 0px 23px 78px rgba(0, 0, 0, 0.0901961);

      @media (max-width: 599px) {
        padding: 44px 0px 42px 38px;
      }

      @media (max-width: 599px) {
        padding: 44px 38px 42px 38px;
      }

      .help-head {
        max-width: 572px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 55px;
        /* identical to box height */
        text-align: center;
        letter-spacing: -1.386px;
        color: #0d152e;
        margin-bottom: 21px;
        @media (max-width: 899px) {
          font-size: 38px;
          line-height: 50px;
          margin-bottom: 16px;
        }
        @media (max-width: 599px) {
          font-size: 25px;
          line-height: 35px;
          margin-bottom: 10px;
        }
        @media (max-width: 360px) {
          font-size: 33px !important;
          line-height: 40px !important;
        }
      }
      .help-describe {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -0.66px;
        color: #81838c;
        max-width: 572px;
        text-align: center;
        margin-bottom: 47px;
        // @media (min-width: 320px) {
        //   font-size: 14px;
        //   line-height: 27px;
        //   text-align: inherit;
        // }
        @media (max-width: 899px) {
          font-size: 18px;
          line-height: 29px;
          margin-bottom: 37px;
          text-align: center;
        }
        @media (max-width: 599px) {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 30px;
          text-align: center;
        }
      }
      .MuiOutlinedInput-root {
        border-radius: 107px;
        margin-bottom: 20px;
        // padding: 10px 14px 10px 28px;
        background-color: #f7f9fb;
        @media (max-width: 899px) {
          margin-bottom: 12px;
          // padding: 5px 0px 5px 18px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #e5e5e5;
          border-width: 1px;
        }
        // border-color: #182748;
        &::placeholder {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          /* identical to box height */
          letter-spacing: -0.528px;
          color: #9ea1ab;
        }
        input {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          /* identical to box height */
          letter-spacing: -0.528px;
          color: #9ea1ab;
          background-color: #f7f9fb;
          padding: 18px 36px 19px 28px;
          max-width: 572px;
          border-radius: 107px;
        }
      }
      .send-btn {
        margin-top: 20px;
        background: #0052fe;
        padding: 20px 266px 20px 266px;
        border-radius: 30px;
        text-align: center;
        text-transform: capitalize;
        font-family: $font-primary;
        box-shadow: 45px 26px 57px rgba(111, 118, 138, 0.156863);

        @media (max-width: 599px) {
          padding: 10px 0px;
          margin-top: 10px;
        }
      }
      .send-btn:hover {
        background-color: #0b4bd4;
      }
    }
  }
}
