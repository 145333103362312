@import "../../assets/scss/variables.scss";

.about-container {
  padding-top: 85px;
  padding-bottom: 30px;
  @media (max-width: 599px) {
    padding-top: 45px;
    padding-bottom: 40px;
    text-align: -webkit-center;
  }

  h1 {
    max-width: 697px;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -1.98px;
    color: $color-black;
    @media (max-width: 768px) {
      font-size: 52px;
      line-height: 52px;
    }
    @media (max-width: 599px) {
      font-size: 31px;
      line-height: 44px;
    }
    @media (max-width: 360px) {
      font-size: 30px;
      line-height: 40px;
    }
    @media (max-width: 320px) {
      font-size: 27px;
      line-height: 34px;
    }
  }

  .sub-head-text {
    max-width: 697px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #81838c;
    text-align: center;
    letter-spacing: -0.66px;
    margin-top: 26px;
    @media (max-width: 599px) {
      font-size: 18px;
      line-height: 26px;
      text-align: justify;
    }
  }

  .top-imgs {
    display: flex;
    justify-content: center;
    margin-top: 108px;

    @media (max-width: 768px) {
      margin-top: 52px;
    }

    .padding-remove {
      @media (max-width: 599px) {
        padding-top: 0px;
      }
    }

    .img-container {
      max-width: 1110px;

      .MuiAvatar-root {
        width: auto;
        height: auto;
      }

      .htop-1 {
        margin-bottom: 47px;
      }
      .htop-2 {
        margin-top: 47px;
        @media (max-width: 768px) {
          margin-top: 0px;
        }
      }
    }
  }

  .empower-section {
    display: flex;
    justify-content: center;
    margin: 115px 0px;
    @media (max-width: 768px) {
      margin: 34px 0px;
    }

    .MuiGrid-item {
      @media (max-width: 599px) {
        padding-top: 0px;
      }
    }
    .empower-container {
      max-width: 1110px;
      .recue-title {
        display: block;
        text-align: center;
      }
      h4 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -1.584px;
        color: #0d152e;
        @media (max-width: 834px) {
          font-size: 42px;
          line-height: 55px;
        }
        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 40px;
        }
        @media (max-width: 599px) {
          font-size: 34px;
          line-height: 38px;
          margin-top: 25px;
          margin-bottom: 20px;
        }
        @media (max-width: 360px) {
          font-size: 32px;
          line-height: 35px;
        }
      }

      .desc {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: -0.66px;
        color: #81838c;
        padding-right: 50px;
        @media (max-width: 768px) {
          padding-right: 0px;
        }
        @media (max-width: 599px) {
          font-size: 18px;
          margin-top: 2px;
          text-align: justify;
        }

        .br {
          @media (max-width: 599px) {
            display: contents;
          }
        }
      }
    }
  }

  .team-section {
    display: flex;
    justify-content: center;

    .div {
      width: 100%;
      max-width: 862px;
    }

    .MuiAvatar-img {
      width: 267px;
      height: 399.9px;
    }

    h3 {
      font-family: "DM Sans";
      font-style: normal;
      text-align: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -1.584px;
      color: #0d152e;
      margin-bottom: 24px;

      @media (max-width: 599px) {
        font-size: 38px;
        line-height: 45px;
      }
    }

    .desc {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-align: center;
      letter-spacing: -0.66px;
      color: #81838c;
      max-width: 610px;
      margin-bottom: 46px;

      @media (max-width: 599px) {
        text-align: justify;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 35px;
      }
    }

    .team-container {
      width: 100%;

      .MuiAvatar-root {
        width: auto;
        height: auto;
      }
      .name {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 29px;
        letter-spacing: -0.726px;
        margin-top: 30px;
        margin-bottom: 9px;
        color: #0d152e;

        @media (max-width: 599px) {
          margin-top: 16px;
          margin-bottom: 6px;
          font-size: 20px;
        }

        @media (max-width: 834px) {
          text-align: center;
        }
      }
      .position {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: -0.528px;
        color: #81838c;
        @media (max-width: 599px) {
          font-size: 14px;
          line-height: 18px;
        }
        @media (max-width: 834px) {
          text-align: center;
        }
      }
    }
  }
}
.bluebg {
  background-image: url(../../assets/images/bg.png);

  .MuiGrid-item {
    // margin-top: 141px;
  }
  // margin-bottom: 148px;
  .download-info {
    .download-app {
      // margin: 141px 0px 135px 0px;
      .download-head {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 60px;
        /* or 125% */
        letter-spacing: -1.584px;
        color: #ffffff;
        max-width: 399px;
        margin: 141px auto 38.5px 80px;
        // @media (min-width: 1280px) {
        //   margin: 141px auto 38.5px 71px;
        // }
        // @media (max-width: 1920px) {
        //   margin: 141px auto 38.5px 80px;
        // }
        @media (max-width: 1024px) {
          margin: 141px auto 38.5px 0px;
        }

        @media (max-width: 599px) {
          margin: 84px 0px 34.5px 0px;
        }
        @media (max-width: 834px) {
          margin: 141px auto 34.5px 0px;
          text-align: center;
        }
        @media (max-width: 899px) {
          max-width: inherit;
          font-size: 45px;
          line-height: 55px;
        }
        .MuiGrid-item {
          margin-top: 117px;
        }
        @media (max-width: 599px) {
          font-size: 41px;
          line-height: 48px;
          margin-bottom: 25.5px;
        }
        @media (max-width: 360px) {
          font-size: 35px;
          line-height: 44px;
          margin-bottom: 25.5px;
        }
        // margin-bottom: 37.5px;
      }

      .store-links {
        display: flex;
        justify-content: flex-start;
        margin-left: 80px;
        @media (max-width: 1024px) {
          margin-left: 0px;
          display: block;
        }
        @media (max-width: 834px) {
          margin-left: 75px;
        }
        @media (max-width: 810px) {
          margin-left: 67px;
        }

        @media (max-width: 768px) {
          margin-left: 45px;
        }
        @media (max-width: 480px) {
          margin-left: 0px;
        }
        @media (max-width: 360px) {
          margin-left: 0px;
        }
        // @media (max-width: 768px) {
        //   margin-left: 48px;
        // }

        // @media (max-width: 599px) {
        //   margin-left: 0px;
        //   display: grid;
        // }

        @media (max-width: 599px) {
          display: block;
          text-align: center;
        }
        @media (max-width: 360px) {
          display: block;
        }
        .app-store {
          margin-right: 19.38px;
          @media (max-width: 599px) {
            margin-right: 0px;
            // margin-bottom: 10.38px;
          }
          @media (max-width: 480px) {
            margin-right: 8px;
          }
          @media (max-width: 414px) {
            margin-right: 0px;
          }
          @media (max-width: 412px) {
            margin-left: 0px;
          }
          @media (max-width: 390px) {
            margin-left: 0px;
          }
          @media (max-width: 379px) {
            margin-left: 0px;
          }
          @media (max-width: 375px) {
            margin-left: 0px;
          }
          @media (max-width: 360px) {
            margin-right: 0px;
          }
        }
        .play-store {
          @media (max-width: 360px) {
            margin-left: 0px;
          }
        }
      }
    }
  }
  .download-img {
    // margin-right: 222px;
    width: auto;
    height: auto;
    margin-bottom: -2px;
    max-width: 505px;
    max-height: 404px;
    margin-top: 103px;
    // border-bottom: 2px solid #ffffff;
  }
}
