@import "../../../assets/scss/variables.scss";

.home-ff-top {
  padding-top: 382px !important;
}
.client-footer {
  padding-top: 73px;
  padding-bottom: 35px;
  background-color: $color-grey-blue;
  color: $color-white;

  .MuiGrid-root {
    padding-top: 112px;
    @media (max-width: 599px) {
      padding-top: 0px;
    }
  }
  .sec-title {
    font-size: 12px;
    line-height: 15.62px;
    font-weight: 700;
    font-family: $font-primary;
    text-transform: uppercase;
    color: #9fa4b1;
    margin-bottom: 43px;
    letter-spacing: 2.41px;
    margin-bottom: 43px;

    @media (max-width: 1279px) {
      margin-top: 35px;
    }
  }

  .opt-menus {
    display: grid;
    .opt-menu {
      margin-bottom: 31px;
      text-decoration: none;
      color: #ffffff;
      font-family: $font-primary;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .newsletter-text {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #ffffff;
    font-family: $font-primary;
    margin-bottom: 30px;
  }
  .email-text {
    background-color: #ffffff;
    // padding: 12px 0px 14px 3px;
    border-radius: 39px;
    width: 85%;
    max-width: 400px;
    @media (max-width: 599px) {
      width: 100%;
    }
    input,
    ::placeholder {
      font-size: 16px;
      line-height: 20.83px;
      font-weight: 400;
      font-family: $font-primary;
      color: #687497;
      letter-spacing: -0.53px;
    }
    .MuiInputBase-root {
      border-radius: 25px;
    }
    .MuiOutlinedInput-root {
      padding-right: 0px;
    }
    .MuiInputBase-inputSizeSmall {
      padding: 20px 0px 19px 31px;
      @media (max-width: 599px) {
        padding: 12px 0px 12px 21px;
      }
      @media (max-width: 1279px) {
        padding: 15px 0px 14px 19px;
      }
    }
    .MuiInputAdornment-root {
      margin-right: 0px;
    }
    .shareicon {
      margin: 4px 0px 4px 0px;
      box-shadow: 45px 26px 57px rgba(111, 118, 138, 0.156863);
      @media (max-width: 599px) {
        height: 50px;
        width: 50px;
      }
      .shareicon:hover {
        background-color: #0b4bd4;
      }
      @media (max-width: 1279px) {
        height: 50px;
        width: 50px;
      }
    }

    .MuiOutlinedInput-root {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiButtonBase-root {
      @media (max-width: 599px) {
        padding-right: 5px;
      }
    }
  }
  .newsletter-mail {
    font-family: DM Sans;
    font-size: 15px;
    &.error {
      color: red;
    }
    &.success {
      color: green;
    }
    &.send {
      color: #fff;
    }
  }

  // COPYRIGHT
  .copyright {
    margin-top: 106px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 599px) {
      display: block;
      text-align: center;
      margin-top: 35px;
    }

    .copyright-text-icon {
      display: flex;
      align-items: center;

      @media (max-width: 599px) {
        display: block;
        text-align: center;
      }

      .copyright-text {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $font-primary;
        color: #eaeced;
        margin-left: 52px;
        @media (max-width: 599px) {
          margin-left: 0px;
        }
      }
    }
    .socialmedia-icon {
      display: flex;
      align-items: center;
      @media (max-width: 599px) {
        justify-content: center;
      }
      .icon {
        padding: 8px;
        height: 24px;
        width: 24px;
        margin-right: 23px;
      }
    }
  }
}
