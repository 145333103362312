@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

// FONTS
$font-primary: DM Sans;

// COLORS
$color-black: #0d152e;
$color-white: #ffff;
$color-blue: #0052fe;
$color-grey-blue: #101c3d;

// BORDERS
$bRadius: 6px;
